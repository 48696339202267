<template>
  <div class="notfound">
    <div class="wrapper">
      <div class="box">
        <div class="img-left"></div>
        <div class="not-right">
          <div class="big">
            <span>404</span> 页面不见了！
          </div>
          <div class="back">点击<router-link to="/home">这里</router-link>进入首页.</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss">
.notfound {
  width: 100%;
  height: 800px;
  background: linear-gradient(to bottom  right, #777488, #110F20);
}

.box {
  width: 600px;
  height: 340px;
  margin: 0 auto;
  padding-top: 60px;
 .img-left {
    float: left;
    width: 300px;
    height: 340px;
    
    background: url('../assets/img/lang.png') no-repeat;
    
 }
 .not-right {
    float: left;
    width: 280px;
    height: 340px;
    font-size: 18px;
    color: rgb(247, 242, 242);
    .big span {
      font-size: 80px;
      font-family:'Times New Roman', Times, serif;
      font-weight: 700;
      text-align: left;
    }

    .back {
      margin-top: 30px;

    }
 }
}

</style>